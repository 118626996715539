import memoize from 'lodash/memoize'
import { createSelector } from 'reselect'

import { ApiFaq } from '@app/constants/ApiTypes/entities'
import { FaqFilter } from '@app/constants/ApiTypes/requests'

import moment from '@app/utils/moment'
import { assertApiActionResponse } from '@app/utils/performFetchData'

import { ActionRequiredError } from '@app/packages/ActionRequiredError/ActionRequiredError'
import { intoResult } from '@app/packages/Result/Result'

import { getFaq } from '@app/store/actions/api/faq'
import { createReduxSlice } from '@app/store/redux_slice'
import { routingDateSelector } from '@app/store/selectors/routing'
import { createThunk } from '@app/store/thunk'

const getFaqSlice = memoize((section: FaqSection) => createReduxSlice<{ loaded_at: number; faq: ApiFaq }>(`faq:${section}`))

export const createFaqSelector = memoize((section: FaqSection) => {
  const faqSlice = getFaqSlice(section)
  return createSelector([faqSlice.selector, routingDateSelector], (slice, date) =>
    intoResult(() => {
      if (!slice || moment.unix(slice.loaded_at).add(1, 'hour').isBefore(date))
        throw ActionRequiredError.create(`Faq:${section} must be fetched`, date.format(), async dispatch => {
          const faq = await dispatch(fetchFaq(section))
          dispatch(faqSlice.set({ loaded_at: date.unix(), faq }))
        })
      return slice.faq
    })
  )
})

export type FaqSection = 'main' | 'parents/landing' | 'sitters/landing' | 'playground/landing' | 'sitters/knowledge'

export const fetchFaq = (section: FaqSection) =>
  createThunk(async dispatch => {
    const filter = ((): FaqFilter => {
      switch (section) {
        case 'main':
          return { 'filter[for_sitters]': 'false', 'filter[for_knowledgebase]': 'false' }
        case 'sitters/knowledge':
          return { 'filter[for_sitters]': 'true', 'filter[for_knowledgebase]': 'true' }
        case 'parents/landing':
          return { 'filter[for_parents]': 'true', 'filter[for_landing]': 'true' }
        case 'playground/landing':
          return { 'filter[for_playground]': 'true', 'filter[for_landing]': 'true' }
        case 'sitters/landing':
          return { 'filter[for_sitters]': 'true', 'filter[for_landing]': 'true' }
      }
    })()
    const resp = await dispatch(getFaq(filter)).then(assertApiActionResponse(dispatch, 'Faq fetch failed'))
    return resp.data
  })
