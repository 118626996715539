import { FaqFilter } from '@app/constants/ApiTypes/requests'

import { api } from '@app/utils/api'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'

import { getFaqDescriptor } from './faq.descriptors'

export const getFaq = new ApiActionBuilder(getFaqDescriptor)
  .setInit((filter?: FaqFilter) => ({
    method: 'GET',
    endpoint: api.path('/api/v2/faq/', filter),
    headers: api.headers(),
    meta: { filter },
  }))
  .build()
